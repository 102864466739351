import { defineStore } from 'pinia'

interface PwaStoreState {
  isNewVersionAvailable: boolean
  update?: () => Promise<void>
}

const INITIAL_STATE: PwaStoreState = {
  isNewVersionAvailable: false,
  update: undefined,
}

const usePWAStore = defineStore('pwa', {
  state: () => ({ ...INITIAL_STATE }),
  actions: {
    setIsNewVersionAvailable(value: boolean) {
      this.isNewVersionAvailable = value
    },
    setUpdateMethod(update: () => Promise<void>) {
      this.update = update
    },
  },
})

export default usePWAStore
