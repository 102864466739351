export interface RegisterSWOptions {
  swSrc: string
  onNeedRefresh?: () => void
  onRegisteredSW?: (registration: ServiceWorkerRegistration | undefined) => void
  onRegisterError?: (error: any) => void
}

export function initRegisterSW(options: RegisterSWOptions) {
  let swRegistration: ServiceWorkerRegistration | undefined

  async function registerServiceWorker() {
    if (!('serviceWorker' in navigator)) {
      return
    }

    try {
      const registration = await navigator.serviceWorker.register(options.swSrc)
      swRegistration = registration

      options.onRegisteredSW?.(registration)

      registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing

        newWorker?.addEventListener('statechange', () => {
          if (
            newWorker.state === 'installed' &&
            navigator.serviceWorker.controller
          ) {
            // New service worker installed, but waiting
            options.onNeedRefresh?.()
          }
        })
      })

      // Handle service worker controlling the page
      if (registration.waiting) {
        options.onNeedRefresh?.()
      }

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload()
      })
    } catch (error) {
      console.warn('SW >> Registration failed:', error)
    }
  }

  async function checkForUpdates() {
    try {
      await swRegistration?.update?.()
    } catch (error) {
      console.warn('SW >> Update check failed:', error)
    }
  }

  async function updateServiceWorker() {
    if (swRegistration?.waiting) {
      swRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
    } else if (swRegistration) {
      // Force update if no waiting worker
      await swRegistration.update()

      // If a new worker is found, trigger update
      if (swRegistration.waiting) {
        swRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    }
  }

  return {
    swRegistration,
    checkForUpdates,
    updateServiceWorker,
    registerServiceWorker,
  }
}
