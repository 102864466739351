<template>
  <JCollapse :show="!!messageType" class="sticky top-0 z-50 bg-white">
    <div
      v-if="messageType === 'offline'"
      class="flex items-center justify-center bg-warning/20 p-10"
    >
      <JIcon name="wifi-off" size="20" />
      <p class="ml-10 font-medium">{{ $t('noNetwork') }}</p>
    </div>

    <div
      v-if="messageType === 'new-pwa-version'"
      class="flex items-center justify-center bg-warning/20 p-10"
      @click="isShowUpdateModal = true"
    >
      <JIcon :name="isReloadingPage ? 'spinner' : 'bell'" size="20" />
      <p class="ml-10 font-medium">{{ $t('newVersionAvailable') }}</p>
      <b class="ms-12 cursor-pointer text-danger">
        {{ $t('newVersionAvailableClick') }}
      </b>
    </div>
  </JCollapse>

  <JModal v-model="isShowUpdateModal" size="sm" center>
    <div class="pb-24">
      <p class="mb-20 font-bold">{{ $t('newVersionAvailable') }}</p>
      <p class="mb-20 whitespace-pre-line">
        {{ $t('newVersionAvailableNote') }}
      </p>
      <JButton
        :isLoading="isReloadingPage"
        :label="$t('newVersionAvailableReload')"
        @click="reloadPage"
      />
    </div>
  </JModal>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { JButton, JCollapse, JIcon, JModal } from '@journey/ui-kit'
import usePWAStore from '@/modules/Core/store/pwa'

const pwaStore = usePWAStore()
const isShowUpdateModal = ref<boolean>(false)
const isReloadingPage = ref<boolean>(false)

function reloadPage() {
  isReloadingPage.value = true
  pwaStore.update?.()
}

const isOnline = ref(navigator.onLine)

function updateOnlineStatus() {
  isOnline.value = navigator.onLine
}

onMounted(() => {
  window.addEventListener('online', updateOnlineStatus)
  window.addEventListener('offline', updateOnlineStatus)
})

onUnmounted(() => {
  window.removeEventListener('online', updateOnlineStatus)
  window.removeEventListener('offline', updateOnlineStatus)
})

const messageType = computed(() => {
  if (!isOnline.value) {
    return 'offline'
  }

  if (pwaStore.isNewVersionAvailable) {
    return 'new-pwa-version'
  }

  return null
})
</script>
