import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import type { Ref } from 'vue'
import type { FetchFriendsSuccessResponse } from '../../types/api/response/friend'
import { axiosInstance } from '@/modules/Core/plugins/axios'
import { filterNonNullQueryParams } from '@/modules/Core/utils'

export const QUERY_KEY = 'update-profile/friends'
const ENDPOINT = '/User/Friend'

export function fetchFriends(query: string, page: number, size: number) {
  const params = filterNonNullQueryParams({ query, page, size })

  return axiosInstance
    .get<FetchFriendsSuccessResponse>(ENDPOINT, {
      params,
    })
    .then((response) => response.data)
}

export function useFriendsQuery(
  query: Ref<string>,
  page: Ref<number>,
  size: Ref<number>,
  options?: UseQueryOptions<Awaited<ReturnType<typeof fetchFriends>>>
) {
  return useQuery({
    queryKey: [QUERY_KEY, page, size, query],
    queryFn: () => fetchFriends(query.value, page.value, size.value),
    ...options,
  })
}
