<template>
  <JModal
    v-model="internalModelValue"
    size="sm"
    :persistent="isLoading"
    :title="$t('support.contactUs')"
  >
    <ContactSupportForm
      :isLoading="isLoading"
      class="pb-16"
      @submit="onSubmit"
      @cancel="onCancel"
    />
  </JModal>

  <ContactSupportConfirmationDialog v-model="confirmationModalModelValue" />
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { JModal } from '@journey/ui-kit'
import { useVModel } from '@vueuse/core'
import ContactSupportForm from '../ContactSupportForm/ContactSupportForm.vue'
import type { Emits, Props } from './ContactSupportModal.types'
import ContactSupportConfirmationDialog from '@/modules/Support/components/ContactSupportConfirmationDialog/ContactSupportConfirmationDialog.vue'
import { useCreateSupportMessageMutation } from '@/modules/Support/requests/mutations'
import type { SupportPayload } from '@/modules/Support/types/api/payload/contactSupport'

// TOGGLE
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const emit = defineEmits<Emits>()

const internalModelValue = useVModel(props, 'modelValue', emit)

// CONFIRM
const { isLoading, mutateAsync } = useCreateSupportMessageMutation()
const confirmationModalModelValue = ref(false)

async function onSubmit(values: SupportPayload) {
  await mutateAsync({
    ...values,
  })

  confirmationModalModelValue.value = true
  internalModelValue.value = false
}

function onCancel() {
  internalModelValue.value = false
}
</script>
