<template>
  <JMenu
    v-model="selectedLocale"
    :options="selectableLocales"
    :title="`${$t('selectLanguage')}`"
    @update:model-value="switchLanguage"
  >
    <template #menuButton>
      <button class="flex" @click="setRecentActivityToLanguage">
        <JIcon v-if="!selectedLocale" name="globe-outline" />

        <span v-else class="flex items-center gap-4">
          <JFlag :name="selectedLocale.flag" />

          <span class="font-semi-bold text-secondary">
            {{ selectedLocale.code.toUpperCase() }}
          </span>
        </span>
      </button>
    </template>

    <template v-if="isLoading" #description>
      <div class="flex flex-col gap-10 p-10">
        <div v-for="n in 5" :key="n" class="flex align-middle">
          <JSkeleton
            :options="{
              type: 'circle',
              width: 30,
              height: 30,
            }"
          />
          <JSkeleton
            class="my-auto w-full ps-10"
            :options="{
              type: 'text',
              textSmall: true,
              textLinesCount: 1,
              textLinesWidth: [n % 2 === 0 ? '80%' : '65%'],
            }"
          />
        </div>
      </div>
    </template>

    <template #listItem="{ menuItem, selectedItem }">
      <JMenuItem
        :option="menuItem"
        :selectedItem="selectedItem"
        :title="menuItem.title"
      >
        <template #prependItem>
          <JFlag :name="menuItem.flag" />
        </template>
        <template #appendItem>
          <JIcon
            v-show="selectedItem"
            name="check-circle-bold-2"
            class="text-primary"
          />
        </template>
      </JMenuItem>
    </template>
  </JMenu>
</template>

<script lang="ts" setup>
import { JFlag, JIcon, JMenu, JMenuItem, JSkeleton } from '@journey/ui-kit'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLocale } from '@/modules/Core/composables'
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '@/modules/Core/plugins/i18n'
import useHelpStore from '@/modules/Help/store'
import { HELP_SECTIONS } from '@/modules/Core/constants'
import { useCustomerLanguageQuery } from '@/modules/Core/requests/queries/useCustomerLanguageQuery'

const selectedLocale = ref(SUPPORTED_LOCALES[0])

const { data, isLoading } = useCustomerLanguageQuery()

const selectableLocales = computed(() => {
  if (isLoading.value) {
    return []
  }

  const localesRef = data.value?.data.map((item) => item.isoCode) || []

  const locales = SUPPORTED_LOCALES.filter((item) =>
    localesRef.includes(item.code)
  ).sort((a, b) => localesRef.indexOf(a.code) - localesRef.indexOf(b.code))

  if (!locales.length) {
    return [selectedLocale.value]
  }

  return locales
})

function findSelectedLocaleByCode(a: string) {
  return SUPPORTED_LOCALES.find((locale) => locale.code === a)
}

const { locale } = useI18n()

onMounted(() => {
  const storedLocale = findSelectedLocaleByCode(locale.value)
  if (storedLocale) {
    selectedLocale.value = storedLocale
  } else {
    selectedLocale.value = DEFAULT_LOCALE
  }
})

const { switchLanguage: switchLang } = useLocale()
async function switchLanguage(selected: Record<string, unknown>) {
  const locale = findSelectedLocaleByCode(selected.code as string)

  if (!locale) {
    return
  }

  await switchLang(locale)
}

// RECENT ACTIVITY
const helpStore = useHelpStore()

function setRecentActivityToLanguage() {
  helpStore.setRecentActivity(HELP_SECTIONS.LanguageOrCurrency)
}
</script>
