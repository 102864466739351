import { defineStore } from 'pinia'
import type {
  EventFiltersForm,
  SearchEventPayload,
} from '../types/api/payload/event'
import {
  EventCategory,
  type SearchEvent,
  type SearchEventDetail,
} from '../types/model/event'

interface State {
  eventFormFilters: EventFiltersForm
  eventsSearchPayload: SearchEventPayload
  events: Array<SearchEvent>
  selectedEvent: Maybe<SearchEventDetail>
  confirmedEvent: SearchEvent | undefined
  isLoading: boolean
  departureTime: string
  arrivalTime: string
  totalPages: number
  currentPage: number
}

function initState() {
  return {
    eventFormFilters: {
      categoryId: EventCategory.Concert,
      artistIds: [],
      genreIds: [],
      sport: '',
      homeTeamId: undefined,
      awayTeamId: undefined,
      typeIds: [],
      leagueIds: [],
      geos: [],
      keywords: [],
      activityIds: [],
    },
    eventsSearchPayload: {
      query: '',
      page: -1,
      size: -1,
      categoryId: EventCategory.Concert,
      typeIds: null,
      artistIds: null,
      genreIds: null,
      leagueIds: null,
      keywords: null,
      geos: [],
      activityIds: null,
      homeTeamId: null,
      awayTeamId: null,
      startDate: '',
      endDate: '',
    },
    events: [],
    isLoading: false,
    selectedEvent: null,
    confirmedEvent: undefined,
    departureTime: '',
    arrivalTime: '',
    totalPages: 1,
    currentPage: 1,
  } as State
}

const useEventSearchResultsStore = defineStore('eventSearchResults', {
  state: () => ({ ...initState() }),
  actions: {
    setEvents(events: Array<SearchEvent>) {
      this.events = events
    },
    setConfirmedEvent(event: SearchEvent) {
      if (this.confirmedEvent && this.confirmedEvent.id === event.id) {
        this.confirmedEvent = undefined
        return
      }

      this.confirmedEvent = event
    },
    setSelectedEvent(event: Maybe<SearchEventDetail>) {
      this.selectedEvent = event
    },
    setIsLoading(loading: boolean) {
      this.isLoading = loading
    },
    setEventTimes(arrivalTime: string, departureTime: string) {
      this.arrivalTime = arrivalTime
      this.departureTime = departureTime
    },
    setEventFormFilters(filters: EventFiltersForm) {
      this.eventFormFilters = filters
    },
    setTotalPages(total: number) {
      this.totalPages = total
    },
    setEventsSearchPayload(payload: SearchEventPayload) {
      this.eventsSearchPayload = payload
    },
    resetEventFormFilters() {
      this.eventFormFilters = initState().eventFormFilters
    },
    resetEventsSearchPayload() {
      this.eventsSearchPayload = initState().eventsSearchPayload
    },
    resetEvents() {
      this.events = []
    },
  },
})

export default useEventSearchResultsStore
