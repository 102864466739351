<template>
  <component :is="layout">
    <RouterView v-slot="{ Component }">
      <Transition
        enterFromClass="opacity-0"
        enterActiveClass="transition duration-300"
      >
        <component :is="Component" />
      </Transition>
    </RouterView>
  </component>

  <!-- Toast/Notification -->
  <Teleport to="#toast">
    <div
      class="fixed inset-x-20 top-0 z-40 mt-20 flex flex-col items-center gap-12 md:inset-x-auto md:left-0 md:m-20 md:transform-none md:items-start ltr:md:left-0 rtl:md:right-0"
    >
      <JToast
        v-for="{ id, title, color } in store.toasts"
        :key="id"
        :title="title"
        :color="color as BGColors"
        @close="store.removeToast(id)"
      />
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import type { BGColors } from '@journey/ui-kit'
import { JToast } from '@journey/ui-kit'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import useToastStore from '@/modules/Core/store/toast'
import { FIREBASE_CONFIG } from '@/config/fcmTokens'

defineOptions({
  name: 'TheAppLayout',
})

const route = useRoute()

const layout = computed(() => {
  const layout = route?.meta?.layout

  if (layout) {
    return `The${layout}Layout`
  }
  return 'div'
})

const store = useToastStore()

const firebaseApp = initializeApp(FIREBASE_CONFIG)

getAnalytics(firebaseApp)
</script>
