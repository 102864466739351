import type { Airport } from '@/modules/Profile/types/model/airport'

export enum EventCategory {
  Concert = '2904579b-8ed7-11ef-bbb1-064df691b4b9',
  Festival = '29045cdc-8ed7-11ef-bbb1-064df691b4b9',
  SportingEvents = '29045e07-8ed7-11ef-bbb1-064df691b4b9',
  CuratedForYou = '497370f1-ef8b-11ef-b284-60452e0d0b6e',
  Activities = '',
}

export interface EventGenre {
  id: string
  genreName: string
  category: string
}

export interface EventArtist {
  id: string
  artistName: string
}

export interface EventType {
  id: string
  eventType: string
  categoryId: string
  eventTypeTag: string
  eventTypeDescription: string
}

export interface EventTeam {
  id: string
  typeId: string
  team: string
  type: string
}

export interface EventLeague {
  id: string
  leagueName: string
  type: string
  typeId: string
}

export interface EventLocation {
  id: string
  geoLocation: string
  location: Airport
}

export interface EventKeyword {
  id: string
  eventTag: string
  eventId: string
}

export interface EventActivity extends EventType {}

export interface SearchEvent {
  id: string
  eventCode: string
  eventPubPrivToggle: number
  geoLocation: string
  eventStartDate: string
  eventEndDate: string
  numTags: number
  eventName: string
  eventDescription: string
  eventVenueName: string
  eventVenueLat: number
  eventVenueLong: number
  insertTimestamp: string
  updateTimestamp: string
  images: Array<string>
}

export interface TopEvent extends SearchEvent {}

export interface SearchEventDetail {
  id: string
  eventCode: string
  eventPubPrivToggle: number
  geoLocation: string
  eventStartDate: string
  eventEndDate: string
  numTags: number
  eventName: string
  eventDescription: string
  eventVenueName: string
  eventVenueLat: number
  eventVenueLong: number
  insertTimestamp: string
  updateTimestamp: string
  artist: EventArtist
  category: {
    id: string
    eventCategory: string
    eventCategoryTag: string
    eventCategoryDescription: string
  }
  location: EventLocation
  genre: EventGenre
  teamAway: EventTeam
  teamHome: EventTeam
  type: EventType
  league: EventLeague
  images: Array<string>
}
