export default {
  en: {
    currencyFormat: {
      style: 'currency' as const,
      currency: 'USD',
    },
  },
  ar: {
    currencyFormat: {
      style: 'currency' as const,
      currency: 'RUB',
    },
  },
  es: {
    currencyFormat: {
      style: 'currency' as const,
      currency: 'EUR',
    },
  },
  de: {
    currencyFormat: {
      style: 'currency' as const,
      currency: 'EUR',
    },
  },
  it: {
    currencyFormat: {
      style: 'currency' as const,
      currency: 'EUR',
    },
  },
  fr: {
    currencyFormat: {
      style: 'currency' as const,
      currency: 'EUR',
    },
  },
  pt: {
    currencyFormat: {
      style: 'currency' as const,
      currency: 'EUR',
    },
  },
  el: {
    currencyFormat: {
      style: 'currency' as const,
      currency: 'EUR',
    },
  },
  bn: {
    currencyFormat: {
      style: 'currency' as const,
      currency: 'BDT',
    },
  },
}
