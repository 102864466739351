import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import { axiosInstance } from '@/modules/Core/plugins/axios'
import type { ContactSupportTypesServerSuccessResponse } from '@/modules/Support/types/api/response/contactSupportTypes'

const QUERY_KEY = 'ticket-type-list'
const ENDPOINT = '/Ticket/Types'

export function fetchSupportTypes() {
  return axiosInstance
    .get<ContactSupportTypesServerSuccessResponse>(ENDPOINT)
    .then((response) => response.data)
}

export function useContactSupportTypesQuery(
  options?: UseQueryOptions<Awaited<ReturnType<typeof fetchSupportTypes>>>
) {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchSupportTypes,
    ...options,
  })
}
