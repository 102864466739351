import searchEn from './en/en.json'
import searchAr from './ar/ar.json'
import searchEs from './es/es.json'
import searchDe from './de/de.json'
import searchIt from './it/it.json'
import searchFr from './fr/fr.json'
import searchPt from './pt/pt.json'
import searchEl from './el/el.json'
import searchBn from './bn/bn.json'

const SearchMessages = {
  en: { search: { ...searchEn } },
  ar: { search: { ...searchAr } },
  es: { search: { ...searchEs } },
  de: { search: { ...searchDe } },
  it: { search: { ...searchIt } },
  fr: { search: { ...searchFr } },
  pt: { search: { ...searchPt } },
  el: { search: { ...searchEl } },
  bn: { search: { ...searchBn } },
}

export default SearchMessages
