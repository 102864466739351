import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import { axiosInstance } from '@/modules/Core/plugins/axios'
import type { FetchCurrencyListSuccessResponse } from '@/modules/Core/types/api/response/currency'

export const QUERY_KEY = 'currency-list'
const ENDPOINT = 'CustomerCurrency'

export function fetchCurrencyList() {
  return axiosInstance
    .get<FetchCurrencyListSuccessResponse>(ENDPOINT)
    .then((response) => response.data.data)
}

export function useCurrencyQuery(
  options?: UseQueryOptions<Awaited<ReturnType<typeof fetchCurrencyList>>>
) {
  return useQuery({
    queryFn: fetchCurrencyList,
    queryKey: [QUERY_KEY],
    ...options,
  })
}
