import type {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import axios from 'axios'
import type { App } from 'vue'
import { useLocale } from '@/modules/Core/composables'
import {
  REFRESH_TOKEN_ENDPOINT,
  // SERVER_ERROR,
  UNAUTHORIZED_STATUS_CODE,
} from '@/modules/Core/constants'
import i18n from '@/modules/Core/plugins/i18n'
import useToastStore from '@/modules/Core/store/toast'
import type { RefreshTokenSuccessResponse } from '@/modules/Auth/types/api/response/refreshToken'
import useAuthStore from '@/modules/Auth/store'
import {
  CARS_SEARCH_DIRECT_ENDPOINT,
  FLIGHTS_SEARCH_DIRECT_ENDPOINT,
  HOTELS_SEARCH_DIRECT_ENDPOINT,
} from '@/modules/Search/requests/mutations'

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Customer-Code': import.meta.env.VITE_CUSTOMER_CODE,
  },
})

function onRequest(config: InternalAxiosRequestConfig) {
  const { currentLocale } = useLocale()
  config.headers['X-Language'] = currentLocale.value

  const authStore = useAuthStore()
  const toastStore = useToastStore()
  const t = i18n.global.t

  if (authStore.loggedIn && !authStore.tokens.access) {
    authStore.logout()

    const message = t('yourSessionHasBeenExpiredPleaseLoginAgain')

    toastStore.addToast({
      color: 'danger',
      title: message,
    })

    return Promise.reject(new Error(message))
  }

  const SEARCH_ENDPOINTS = [
    FLIGHTS_SEARCH_DIRECT_ENDPOINT,
    HOTELS_SEARCH_DIRECT_ENDPOINT,
    CARS_SEARCH_DIRECT_ENDPOINT,
  ]

  // DISABLE AUTH FOR SEARCH
  if (config.url && !SEARCH_ENDPOINTS.includes(config.url)) {
    config.headers.Authorization = `Bearer ${authStore.tokens.access}`
  }

  return config
}

function onResponse(config: AxiosResponse) {
  return config
}

async function onResponseError(error: AxiosError<ServerResponse>) {
  if (!error.response) {
    throw error
  }

  const originalConfig = error.config

  if (!originalConfig) {
    throw error
  }

  const authStore = useAuthStore()
  const toastStore = useToastStore()
  const t = i18n.global.t

  // if (error.response.status === SERVER_ERROR) {
  //   await router.push({ name: 'ServerError' })
  //
  //   return
  // }

  if (authStore.loggedIn && originalConfig.url === REFRESH_TOKEN_ENDPOINT) {
    authStore.logout()

    toastStore.addToast({
      color: 'danger',
      title: t('yourSessionHasBeenExpiredPleaseLoginAgain'),
    })

    return
  }

  if (
    authStore.loggedIn &&
    error.response.status === UNAUTHORIZED_STATUS_CODE
  ) {
    const { data } = await axiosInstance.post<RefreshTokenSuccessResponse>(
      REFRESH_TOKEN_ENDPOINT,
      {
        refreshToken: authStore.tokens.refresh,
      }
    )

    authStore.setTokens(data)

    await axiosInstance.request(originalConfig)
  }

  throw error
}

axiosInstance.interceptors.request.use(onRequest)
axiosInstance.interceptors.response.use(onResponse, onResponseError)

function registerAxios(app: App) {
  app.config.globalProperties.$axios = { ...axiosInstance }
}

export { axiosInstance, registerAxios }
