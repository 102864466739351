import type { LocaleItem } from './i18n.types'
import featureFlag from '@/config/featureFlag.json'

export const SUPPORTED_LOCALES: LocaleItem[] = [
  {
    code: 'en',
    title: 'English',
    flag: 'england',
    rtl: false,
  },
  {
    code: 'ar',
    title: 'Arabic',
    flag: 'arabia',
    rtl: true,
  },
  {
    code: 'es',
    title: 'Spanish',
    flag: 'spain',
    rtl: false,
  },
  {
    code: 'de',
    title: 'German',
    flag: 'germany',
    rtl: false,
  },
  {
    code: 'it',
    title: 'Italian',
    flag: 'italy',
    rtl: false,
  },
  {
    code: 'fr',
    title: 'French',
    flag: 'france',
    rtl: false,
  },
  {
    code: 'pt',
    title: 'Portuguese',
    flag: 'portugal',
    rtl: false,
  },
  {
    code: 'el',
    title: 'Greek',
    flag: 'greece',
    rtl: false,
  },
  {
    code: 'bn',
    title: 'Bengali',
    flag: 'bangladesh',
    rtl: false,
  },
]

export const DEFAULT_LOCALE =
  SUPPORTED_LOCALES.find(({ code }) => code === featureFlag.locale.default) ||
  SUPPORTED_LOCALES[0]
