import bookEn from './en/en.json'
import bookAr from './ar/ar.json'
import bookEs from './es/es.json'
import bookDe from './de/de.json'
import bookIt from './it/it.json'
import bookFr from './fr/fr.json'
import bookPt from './pt/pt.json'
import bookEl from './el/el.json'
import bookBn from './bn/bn.json'

const BookMessages = {
  en: { book: { ...bookEn } },
  ar: { book: { ...bookAr } },
  es: { book: { ...bookEs } },
  de: { book: { ...bookDe } },
  it: { book: { ...bookIt } },
  fr: { book: { ...bookFr } },
  pt: { book: { ...bookPt } },
  el: { book: { ...bookEl } },
  bn: { book: { ...bookBn } },
}

export default BookMessages
