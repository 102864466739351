import { defineStore } from 'pinia'
import type { RoomRateWithQuantity } from '../types'
import type {
  HotelInfo,
  RoomRate,
} from '@/modules/Search/types/model/hotelInfo'
import type { Hotel } from '@/modules/Search/types/model/search'

export interface HotelToReserve {
  uniqueId: string
  hotelCode: number
  adults: number
  children: number
  name: string
  location: string
  rooms: RoomRate[]
  price: number
  checkOutDate: string
  checkInDate: string
  hotelRate: number
  destinationCode: string
  destinationName: string
  convertedCurrency: string
  provider: string
  searchOption: number
  latitude: number
  longitude: number
}

interface State {
  hotels: Maybe<Hotel[]>
  selectedHotel: Maybe<HotelInfo>
  hotelToReserve: HotelToReserve
  totalHotelCount: number
  totalHotelPageCount: number
  rooms: Array<RoomRateWithQuantity>
  isLoading: boolean
  isSearchEmpty: boolean
}

function initState(): State {
  return {
    hotels: null,
    selectedHotel: null,
    hotelToReserve: {
      provider: '',
      convertedCurrency: '',
      uniqueId: '',
      name: '',
      rooms: [],
      price: 0,
      adults: 1,
      children: 0,
      hotelCode: 0,
      hotelRate: 0,
      checkOutDate: '',
      checkInDate: '',
      location: '',
      destinationCode: '',
      destinationName: '',
      searchOption: 0,
      latitude: 0,
      longitude: 0,
    },
    totalHotelCount: 0,
    totalHotelPageCount: 0,
    rooms: [],
    isLoading: false,
    isSearchEmpty: true,
  }
}

const useHotelSearchResultsStore = defineStore('hotelSearchResults', {
  state: (): State => ({ ...initState() }),
  getters: {
    hotelsList(state) {
      return state.hotels ?? []
    },
    selectedHotelFacilities: (state) => {
      if (!state?.selectedHotel) {
        return []
      }

      return state.selectedHotel?.hotel.facilities.map((facility) => {
        return facility.description.content
      })
    },
    selectedHotelAddress: (state) => {
      const address = state.selectedHotel?.hotel.address.content
      const city = state.selectedHotel?.hotel.destination.name.content
      const country = state.selectedHotel?.hotel.country.description.content

      return `${address?.replace(' .', '')}, ${city}, ${country}`
    },
    selectedHotelImages: (state) => {
      if (!state.selectedHotel) {
        return []
      }

      return state.selectedHotel.hotel.images.map((image) => {
        return {
          src:
            image.pathList.find((path) => path.size === 'xl')?.address ?? '0',
          alt: `${state.selectedHotel?.hotel.name.content} ${image.type.description.content}`,
        }
      })
    },
    roomsPrice: (state) => {
      return state.rooms.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.roomRate.price * currentValue.quantity,
        0
      )
    },
  },
  actions: {
    setHotels(hotels: Hotel[]) {
      this.hotels = hotels
    },
    setSelectedHotel(selectedHotel: Maybe<HotelInfo>) {
      this.selectedHotel = selectedHotel
    },
    clearSelectedHotel() {
      this.selectedHotel = null
    },
    setHotelToReserve(hotelTeReserve: HotelToReserve) {
      this.hotelToReserve = {
        ...hotelTeReserve,
        price: Number(hotelTeReserve.price.toFixed(2)),
      }
    },
    resetHotels() {
      this.hotels = []
    },
    appendHotels(hotels: Array<Hotel>) {
      if (!hotels) {
        this.hotels = hotels
        return
      }

      if (!this.hotels) {
        this.hotels = []
      }

      this.hotels?.push(...hotels)
    },
    setTotalHotelCount(totalHotelCount: number) {
      this.totalHotelCount = totalHotelCount
    },
    setTotalHotelPages(totalHotelPageCount: number) {
      this.totalHotelPageCount = totalHotelPageCount
    },
    selectRoom({ quantity, roomRate }: RoomRateWithQuantity) {
      const found = this.rooms.find(
        (item) => item.roomRate.roomCode === roomRate.roomCode
      )

      if (found) {
        const selectedRoomIndex = this.rooms.findIndex(
          (room) => room.roomRate.roomCode === roomRate.roomCode
        )

        this.rooms.splice(selectedRoomIndex, 1)
      } else {
        this.rooms = [{ quantity, roomRate }]
      }
    },
    setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading
    },
    setIsSearchEmpty(value: boolean) {
      this.isSearchEmpty = value
    },
    $reset() {
      Object.assign(this, initState())
    },
  },
})

export default useHotelSearchResultsStore
