import tripsEn from './en/en.json'
import tripsAr from './ar/ar.json'
import tripsEs from './es/es.json'
import tripsDe from './de/de.json'
import tripsIt from './it/it.json'
import tripsFr from './fr/fr.json'
import tripsPt from './pt/pt.json'
import tripsEl from './el/el.json'
import tripsBn from './bn/bn.json'

const TripsMessages = {
  en: { trips: { ...tripsEn } },
  ar: { trips: { ...tripsAr } },
  es: { trips: { ...tripsEs } },
  de: { trips: { ...tripsDe } },
  it: { trips: { ...tripsIt } },
  fr: { trips: { ...tripsFr } },
  pt: { trips: { ...tripsPt } },
  el: { trips: { ...tripsEl } },
  bn: { trips: { ...tripsBn } },
}

export default TripsMessages
