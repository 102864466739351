import indexEn from './en/en.json'
import indexAr from './ar/ar.json'
import indexEs from './es/es.json'
import indexDe from './de/de.json'
import indexIt from './it/it.json'
import indexFr from './fr/fr.json'
import indexPt from './pt/pt.json'
import indexEl from './el/el.json'
import indexBn from './bn/bn.json'

const IndexMessages = {
  en: { index: { ...indexEn } },
  ar: { index: { ...indexAr } },
  es: { index: { ...indexEs } },
  de: { index: { ...indexDe } },
  it: { index: { ...indexIt } },
  fr: { index: { ...indexFr } },
  pt: { index: { ...indexPt } },
  el: { index: { ...indexEl } },
  bn: { index: { ...indexBn } },
}

export default IndexMessages
