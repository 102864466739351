<template>
  <form
    novalidate
    class="flex h-full flex-col justify-between gap-12"
    @submit="onSubmit"
  >
    <JRow spaceMd="12" spaceLg="12" spaceXl="12" class="mb-12 gap-y-16">
      <JCol>
        <JTextInput
          v-model="ticketEmail.value.value"
          :error="ticketEmail.errorMessage.value"
          type="email"
          :label="t('support.form.email.label')"
          :placeholder="t('support.form.email.placeholder')"
          required
        />
      </JCol>

      <JCol>
        <ContactSupportFormTypesSelectInput
          v-model="ticketTypeId.value.value"
          :error="ticketTypeId.errorMessage.value"
          :label="t('support.form.ticketType.label')"
          :placeholder="t('support.form.ticketType.placeholder')"
          required
        />
      </JCol>

      <JCol>
        <JTextArea
          v-model="ticketMessage.value.value"
          :error="ticketMessage.errorMessage.value"
          :label="t('support.form.ticketMessage.label')"
          :placeholder="t('support.form.ticketMessage.placeholder')"
          required
          rows="6"
        />
      </JCol>
    </JRow>

    <div class="flex gap-12">
      <JButton
        block
        class="rounded-md border !border-line"
        color="gray-100"
        type="button"
        :disabled="isLoading"
        @click.prevent="emit('cancel')"
      >
        {{ t('words.cancel') }}
      </JButton>

      <JButton
        block
        class="rounded-md"
        type="submit"
        :isLoading="isLoading"
        :disabled="isLoading"
      >
        {{ t('words.submit') }}
      </JButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { object, string } from 'yup'
import { useField, useForm } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { JButton, JCol, JRow, JTextArea, JTextInput } from '@journey/ui-kit'
import type { Emits, Form, Props } from './ContactSupportForm.types'
import ContactSupportFormTypesSelectInput from './ContactSupportFormTypesSelectInput/ContactSupportFormTypesSelectInput.vue'
import { REGEX } from '@/modules/Core/constants'

import useAuthStore from '@/modules/Auth/store'

defineProps<Props>()

const emit = defineEmits<Emits>()

const { t } = useI18n()
const authStore = useAuthStore()

const validationSchema = object({
  ticketEmail: string()
    .matches(REGEX.EMAIL, t('support.form.email.validation.valid'))
    .transform((value) => value || null)
    .required(t('support.form.email.validation.required')),
  ticketMessage: string()
    .max(500, t('support.form.ticketMessage.validation.max'))
    .required(t('support.form.ticketMessage.validation.required')),
  ticketTypeId: string().required(
    t('support.form.ticketType.validation.required')
  ),
})

const { handleSubmit } = useForm<Form>({
  validationSchema,
  initialValues: {
    ticketEmail: authStore.loggedIn ? authStore.user.email : '',
  },
})

const ticketMessage = useField<Form['ticketMessage']>('ticketMessage')
const ticketEmail = useField<Form['ticketEmail']>('ticketEmail')
const ticketTypeId = useField<Form['ticketTypeId']>('ticketTypeId')

const onSubmit = handleSubmit((values) => {
  emit('submit', values)
})
</script>
