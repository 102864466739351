import { ref } from 'vue'
import usePWAStore from '@/modules/Core/store/pwa'
import { initRegisterSW } from '@/modules/Core/plugins/serviceWorker'

const SW_SRC = '/sw.js'
const IMMEDIATE_AUTO_UPDATE = true
const UPDATE_INTERVAL_DURATION = 10 * 60 * 1000 // 10m

export default function useUpdateServiceWorker() {
  const pwaStore = usePWAStore()
  const updateCheckInterval = ref<ReturnType<typeof setTimeout>>()

  const initialTimestamp = ref(Date.now())

  const { updateServiceWorker, registerServiceWorker, checkForUpdates } =
    initRegisterSW({
      swSrc: SW_SRC,

      onRegisteredSW() {
        checkForUpdates()

        updateCheckInterval.value = setInterval(
          checkForUpdates,
          UPDATE_INTERVAL_DURATION
        )
      },

      onNeedRefresh() {
        if (updateCheckInterval.value) {
          clearInterval(updateCheckInterval.value)
        }

        const isUpdateFoundImmediateAfterPageLoad =
          Date.now() - initialTimestamp.value < 2000

        setTimeout(() => {
          if (IMMEDIATE_AUTO_UPDATE && isUpdateFoundImmediateAfterPageLoad) {
            updateServiceWorker()
          } else {
            pwaStore.setIsNewVersionAvailable(true)
          }
        }, 2000)
      },
    })

  pwaStore.setUpdateMethod(updateServiceWorker)

  return { registerServiceWorker, updateServiceWorker }
}
