import { format } from 'date-fns'
import { ar, bn, de, el, es, fr, it, pt } from 'date-fns/locale'
import { ref, watch } from 'vue'
import useLocale from './useLocale'

export default function useFormatDate(date: string, formatter = 'dd/MM/yyyy') {
  const locales = {
    ar,
    es,
    de,
    it,
    fr,
    pt,
    el,
    bn,
  }

  const { currentLocale } = useLocale()
  const formattedDate = ref('')

  watch(
    currentLocale,
    (locale) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      const localeObj = locales[locale]

      formattedDate.value = format(new Date(date), formatter, {
        locale: locale && locale === 'en' ? undefined : localeObj,
      })
    },
    {
      deep: true,
      immediate: true,
    }
  )

  return {
    formattedDate,
  }
}
