import type { App } from 'vue'
import VueGtag from 'vue-gtag'
import type { Router } from 'vue-router'

export function setupGtag(app: App, router: Router) {
  app.use(
    VueGtag,
    {
      config: { id: import.meta.env.VITE_GTAG_ID as string },
      appName: 'BudgetFly',
    },
    router
  )
}
