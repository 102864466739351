import helpEn from './en/en.json'
import helpAr from './ar/ar.json'
import helpEs from './es/es.json'
import helpDe from './de/de.json'
import helpIt from './it/it.json'
import helpFr from './fr/fr.json'
import helpPt from './pt/pt.json'
import helpEl from './el/el.json'
import helpBn from './bn/bn.json'

const HelpMessages = {
  en: { help: { ...helpEn } },
  ar: { help: { ...helpAr } },
  es: { help: { ...helpEs } },
  de: { help: { ...helpDe } },
  it: { help: { ...helpIt } },
  fr: { help: { ...helpFr } },
  pt: { help: { ...helpPt } },
  el: { help: { ...helpEl } },
  bn: { help: { ...helpBn } },
}

export default HelpMessages
