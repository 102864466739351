import type { Ref } from 'vue'
import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import { PRIVATE_SUPPLIERS_QUERY_KEY } from '../queries'
import type { PrivateSupplierPayload } from '@/modules/Profile/types/api/payload/privateSupplier'
import type { UpdatePrivateSupplierSuccessResponse } from '@/modules/Profile/types/api/response/privateSupplier'
import { axiosInstance } from '@/modules/Core/plugins/axios'

const ENDPOINT = '/Supplier'

export function updateSupplier(payload: PrivateSupplierPayload) {
  return axiosInstance.put<UpdatePrivateSupplierSuccessResponse>(
    ENDPOINT,
    payload
  )
}

export function useUpdateMySupplierMutation(
  type: Ref<string>,
  options?: UseMutationOptions<
    AxiosResponse<UpdatePrivateSupplierSuccessResponse>,
    AxiosCustomError,
    PrivateSupplierPayload,
    any
  >
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateSupplier,
    async onSuccess() {
      await queryClient.invalidateQueries([PRIVATE_SUPPLIERS_QUERY_KEY, type])
    },
    ...options,
  })
}
