<template>
  <JTooltip placement="bottom">
    <template #activator>
      <JIcon
        name="user-outline"
        @click="helpStore.setRecentActivity(HELP_SECTIONS.Accounting)"
      />
    </template>

    <template #content>
      <div class="flex flex-col px-20 py-12">
        <div class="mb-8 flex items-center gap-x-4 text-pane-header">
          <JIcon name="user-outline" class="text-primary" size="32" />

          <div v-if="authStore.userFullName" class="max-w-sm truncate">
            <span class="text-subject font-bold">
              {{ authStore.userFullNameWithMiddleName }}
            </span>
          </div>

          <div v-else class="font-medium">
            {{ authStore.user.email || authStore.fullPhoneNumber }}
          </div>
        </div>

        <div v-if="authStore.userFullName" class="mb-16 text-primary">
          {{ authStore.user.email || authStore.fullPhoneNumber }}
        </div>

        <div class="-mx-20 border-t border-disabled pt-16">
          <div class="flex w-full cursor-pointer flex-col gap-8 px-20 pb-16">
            <div class="flex flex-row items-center gap-8">
              <JIcon name="bell" size="16" />

              <span class="font-bold">
                {{ $t('pushNotifications') }}
              </span>

              <JTooltip arrow>
                <template #activator>
                  <JIcon
                    class="text-gray-600"
                    name="question-mark-outline"
                    size="16"
                  />
                </template>

                <template #content>
                  <div class="px-12 py-8">
                    {{ $t('pushNotificationsGuide') }}
                  </div>
                </template>
              </JTooltip>
            </div>

            <div
              class="flex flex-row justify-between gap-16 font-medium text-gray-500"
            >
              {{ $t('receivePushNotificationsOnWeb') }}

              <JSwitch
                v-model="notificationPreferences.isWebActive"
                @update:model-value="updateWebPushNotifications"
              />
            </div>

            <div
              class="flex flex-row justify-between gap-16 font-medium text-gray-500"
            >
              {{ $t('receivePushNotificationsOnMobile') }}

              <JSwitch
                v-model="notificationPreferences.isMobileActive"
                @update:model-value="updateMobilePushNotifications"
              />
            </div>
          </div>

          <div class="border-t border-disabled px-16 pb-4 pt-12">
            <JButton
              color="danger"
              variant="outlined"
              block
              class="rounded-md"
              @click="emitLogout"
            >
              {{ $t('auth.logout') }}
            </JButton>
          </div>
        </div>
      </div>
    </template>
  </JTooltip>
</template>

<script lang="ts" setup>
import { JButton, JIcon, JSwitch, JTooltip } from '@journey/ui-kit'
import type { Emits } from './HeaderContentProfile.types'
import { usePushNotification } from '@/modules/Core/composables'
import useAuthStore from '@/modules/Auth/store'
import { HELP_SECTIONS } from '@/modules/Core/constants'
import useHelpStore from '@/modules/Help/store'

const emit = defineEmits<Emits>()

const authStore = useAuthStore()
const helpStore = useHelpStore()

// PUSH NOTIFICATIONS
const {
  notificationPreferences,
  updateWebPushNotifications,
  updateMobilePushNotifications,
} = usePushNotification()

function emitLogout() {
  emit('logout')
}
</script>
