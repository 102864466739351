import { defineStore } from 'pinia'
import { addDays, format, parse } from 'date-fns'
import { nanoid } from 'nanoid'
import { DATE_FORMAT } from '../components/SearchPanel/SearchPanelForm/SearchPanelFormFields/SearchPanelFormFields.constants'
import type { InterestCity } from '../types/model/interestCity'
import {
  type SearchPanel,
  type SearchPanelDestinationItems,
  type SearchPanelFlight,
  type SearchPanelMain,
  SearchTravelModes,
  type SearchType,
} from '@/modules/Search/types'
import useAuthStore from '@/modules/Auth/store'
import type { Airport } from '@/modules/Profile/types/model/airport'
import { basePassengerInfo } from '@/modules/Search/constants'

export const INITIAL_STATE: SearchPanel = {
  searchId: undefined,
  guestId: nanoid(),
  main: {
    tripType: 'RoundTrip',
    arrivalTime: '',
    departureTime: format(new Date(), DATE_FORMAT),
    attributesDestination: undefined,
    origin: undefined,
    destination: undefined,
    daysOfDuration: undefined,
    bags: 0,
    passengersWithRoom: [basePassengerInfo()],
    searchBy: 'Destination',
    daysToStay: undefined,
    modes: [SearchTravelModes.Flight],
    interestsAndActivities: [],
    eventStartTime: format(new Date(), DATE_FORMAT),
    eventEndTime: '',
    eventDaysToStay: undefined,
    eventDestination: undefined,
  },
  destinations: [],
  interestsAndActivities: [],
  attributeOptions: [],
}

const KEY = 'searchPanel'

const useSearchPanelStore = defineStore(KEY, {
  state: () => ({ ...INITIAL_STATE }),
  getters: {
    getId: (state) => {
      const authStore = useAuthStore()
      return authStore.loggedIn ? authStore.user.id : state.guestId
    },
    roundTripIsOnTheSameDate: (state) =>
      state.main.departureTime === state.main.arrivalTime,
    isAttributeOptionSelected: (state) => (payload: string) => {
      return !!state.attributeOptions.find(
        ({ geoLocation }) => payload === geoLocation
      )
    },
    attributeLocation: (state) => {
      const attr = state.main.attributesDestination

      if (!attr) {
        return
      }

      if (attr.locationType === 'Country' && attr.geoCountry) {
        return attr.geoCountry
      } else if (attr.locationType === 'Continent' && attr.geoContinent) {
        return attr.geoContinent
      } else {
        return attr.geoCity as string
      }
    },
    isMultiDestination: (state) => !!state.destinations.length,
    isOneWay: (state) => state.main.tripType === 'OneWay',
    isRoundTrip: (state) => state.main.tripType === 'RoundTrip',
    isSearchByDestination: (state) => state.main.searchBy === 'Destination',
    isSearchByAttributes: (state) => state.main.searchBy === 'Attributes',
    isSearchByEvents: (state) => state.main.searchBy === 'Events',
    isFlightModeOnly: (state) =>
      state.main.modes.includes(SearchTravelModes.Flight) &&
      state.main.modes.length === 1,
    destinationList: (state) => {
      const isSearchByDestination = state.main.searchBy === 'Destination'
      const isSearchEvents = state.main.searchBy === 'Events'
      const isOneWay = state.main.tripType === 'OneWay'

      const mainDestination = {
        origin: state.main.origin,
        ...(isSearchByDestination
          ? { destination: state.main.destination }
          : isSearchEvents
          ? { destination: state.main.eventDestination }
          : { destination: state.main.attributesDestination }),
        departureTime: state.main.departureTime,
        ...(isOneWay
          ? {
              ...(state.main.daysToStay && {
                arrivalTime: format(
                  addDays(
                    parse(state.main.departureTime, DATE_FORMAT, new Date()),
                    Number(state.main.daysToStay)
                  ),
                  DATE_FORMAT
                ),
              }),
            }
          : { arrivalTime: state.main.arrivalTime }),
      }

      const destinations: SearchPanelDestinationItems = [
        mainDestination,
        ...state.destinations.map(
          ({ dateOfTravel, durationOfLocation, destination, origin }) => ({
            origin,
            destination,
            departureTime: dateOfTravel,
            arrivalTime: format(
              addDays(
                parse(dateOfTravel, DATE_FORMAT, new Date()),
                Number(durationOfLocation)
              ),
              DATE_FORMAT
            ),
          })
        ),
      ]

      return destinations
    },
    roundTripDestinationList(): SearchPanelDestinationItems {
      const roundTripDestinationList = [...this.destinationList]

      roundTripDestinationList.push({
        origin:
          roundTripDestinationList[roundTripDestinationList.length - 1]
            .destination,
        destination: roundTripDestinationList[0].origin,
        departureTime:
          roundTripDestinationList[roundTripDestinationList.length - 1]
            .arrivalTime ?? '',
        arrivalTime:
          roundTripDestinationList[roundTripDestinationList.length - 1]
            .arrivalTime ?? '',
      })

      return roundTripDestinationList
    },
    totalAdultPassengers: (state) => {
      return state.main.passengersWithRoom.reduce(
        (acc, { adults }) => acc + adults,
        0
      )
    },
    totalChildPassengers: (state) => {
      return state.main.passengersWithRoom.reduce(
        (acc, { children }) => acc + children,
        0
      )
    },
    totalPassengers(): number {
      return (
        Number(this.totalAdultPassengers) + Number(this.totalChildPassengers)
      )
    },
  },
  actions: {
    setSearchId(payload: string) {
      this.searchId = payload
    },
    setMain(payload: Partial<SearchPanelMain>) {
      this.main = {
        ...this.main,
        ...payload,
      }
    },
    setInterestsAndActivities(payload: string[]) {
      this.interestsAndActivities = payload
    },
    addDestination(payload: SearchPanelFlight) {
      this.destinations.push(payload)
    },
    removeDestination(index: number) {
      this.destinations.splice(index, 1)
    },
    updateDestinationsDate(payload: string) {
      if (!this.destinations.length) {
        return
      }

      this.destinations[0].dateOfTravel = payload

      for (let i = 0; i < this.destinations.length - 1; i++) {
        const newDate = format(
          addDays(
            parse(this.destinations[i].dateOfTravel, DATE_FORMAT, new Date()),
            Number(this.destinations[i].durationOfLocation)
          ),
          DATE_FORMAT
        )

        this.destinations[i + 1].dateOfTravel = newDate
      }
    },
    setDestinations(payload: SearchPanelFlight[]) {
      this.destinations = payload
    },
    selectAttributeOption(payload: InterestCity) {
      const foundIndex = this.attributeOptions.findIndex(
        ({ geoLocation }) => payload.geoLocation === geoLocation
      )

      if (foundIndex >= 0) {
        this.attributeOptions.splice(foundIndex, 1)

        return
      }

      this.attributeOptions.push(payload)
    },
    setAttributeOptions(payload: Array<InterestCity>) {
      this.attributeOptions = [...payload]
    },
    clearAttributeOptions() {
      this.attributeOptions = []
    },
    setPayload(payload: Omit<SearchPanel, 'searchId' | 'guestId'>) {
      this.setMain(payload.main)
      this.setDestinations(payload.destinations)
      this.attributeOptions = payload.attributeOptions
      this.setInterestsAndActivities(payload.interestsAndActivities)
    },
    setSearchBy(searchBy: SearchType) {
      this.setMain({ searchBy })
    },
    setEventDestination(payload: Airport) {
      this.main.eventDestination = payload
    },
    appendRoomToPassengers() {
      this.main.passengersWithRoom = [
        ...this.main.passengersWithRoom,
        { ...basePassengerInfo() },
      ]
    },
    removeLastPassengerRoom() {
      this.main.passengersWithRoom = this.main.passengersWithRoom.slice(0, -1)
    },
    reset() {
      this.$reset()
    },
  },
  persist: {
    storage: window.localStorage,
    paths: ['searchId'],
  },
})

export default useSearchPanelStore
