import type { App } from 'vue'
import {
  MutationCache,
  QueryClient,
  VueQueryPlugin,
  type VueQueryPluginOptions,
} from '@tanstack/vue-query'
import { useDevicePlatform } from '../composables'
import useToastStore from '@/modules/Core/store/toast'
import i18n from '@/modules/Core/plugins/i18n'

export function vueQuery(app: App) {
  const toastStore = useToastStore()
  const { isMobile } = useDevicePlatform()
  const t = i18n.global.t

  const mutationCache = new MutationCache({
    onSuccess(_, __, ___, { options }) {
      if (options.meta?.noSuccessToast) {
        return
      }

      toastStore.addToast({
        color: 'success',
        title: t('toastMessages.success'),
      })
    },
    onError(err) {
      const error = err as AxiosCustomError

      if (!error.response) {
        return
      }

      error.response.data.errors.forEach(({ errorMessage }) => {
        toastStore.addToast({
          color: 'danger',
          title: errorMessage,
        })
      })
    },
  })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: Number.POSITIVE_INFINITY,
        cacheTime: isMobile.value ? 0 : 1000 * 60 * 5, // 5 minutes,
      },
    },
    mutationCache,
  })
  const options: VueQueryPluginOptions = { queryClient }

  app.use(VueQueryPlugin, options)
}
