import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { CreateFamilyMemberPayload } from '../../types/api/payload/familyMember'
import type { CreateFamilyMemberSuccessResponse } from '../../types/api/response/familyMember'
import { FAMILY_MEMBER_QUERY_KEY } from '../queries'
import { axiosInstance } from '@/modules/Core/plugins/axios'

const ENDPOINT = '/Family/Member'

export function createFamilyMember(payload: CreateFamilyMemberPayload) {
  return axiosInstance.post<CreateFamilyMemberSuccessResponse>(
    ENDPOINT,
    payload
  )
}

export function useCreateFamilyMemberMutation(
  options?: UseMutationOptions<
    AxiosResponse<CreateFamilyMemberSuccessResponse>,
    AxiosCustomError,
    CreateFamilyMemberPayload,
    any
  >
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: CreateFamilyMemberPayload) =>
      createFamilyMember(payload),
    ...options,
    async onSuccess() {
      await queryClient.invalidateQueries([FAMILY_MEMBER_QUERY_KEY])
    },
  })
}
