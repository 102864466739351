<template>
  <JContainer>
    <TheMobileHeader
      :title="$t('profile.title')"
      showBack
      @back="returnPreviousRoute"
    />

    <h4 class="my-16 text-subject font-bold text-primary">
      {{ $t('profile.updateMyPersonalInformation') }}
    </h4>

    <div class="flex flex-col gap-8">
      <button
        v-for="(section, index) in profileSections"
        :key="`section-${index}`"
        class="flex justify-between rounded-md p-16 shadow-md"
        @click="openForm(section)"
      >
        <span class="text-subject text-gray-500">
          {{ section.title }}
        </span>

        <JIcon
          :name="
            currentLocaleItem.rtl
              ? 'alt-arrow-left-line'
              : 'alt-arrow-right-line'
          "
          class="text-gray-500"
        />
      </button>
    </div>

    <JBottomSheet
      :modelValue="isOpen"
      disableSwipe
      fullScreen
      class="z-20"
      hasPopstate
      :onBeforeClose="closeForm"
    >
      <template #header>
        <JContainer class="p-16">
          <TheMobileHeader
            v-if="profileStore.currentProfileSection"
            :title="$t(profileStore.currentProfileSection?.title)"
            :showBack="true"
            @back="closeForm"
          />
        </JContainer>
      </template>

      <JContainer v-if="form" class="p-16 pb-32">
        <KeepAlive>
          <Component :is="form" />
        </KeepAlive>
      </JContainer>
    </JBottomSheet>
  </JContainer>

  <slot />
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, inject, markRaw } from 'vue'
import { useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { JBottomSheet, JContainer, JIcon } from '@journey/ui-kit'
import type { UpdateProfileSection } from '@/modules/Profile/types'
import { useLocale } from '@/modules/Core/composables'
import useProfileStore from '@/modules/Profile/store'
import TheMobileHeader from '@/modules/Core/components/layout/MobileHeader/TheMobileHeader.vue'
import {
  UPDATE_PROFILE_SECTIONS,
  updateProfileSectionSharedStateInjectionKey,
} from '@/modules/Profile/constants'

const { t } = useI18n()

useHead({
  titleTemplate: (title) => `${title} - ${t('profile.title')}`,
})

const profileStore = useProfileStore()

const router = useRouter()
const { currentLocaleItem } = useLocale()

const isOpen = computed(() => !!profileStore.currentProfileSection)

function openForm(form: Omit<UpdateProfileSection, 'content'>) {
  profileStore.setCurrentProfileSection(findFormThatIsOpening(form.title))

  assignComponent(form.form)
}

function assignComponent(form: string) {
  if (!form) {
    return
  }

  profileStore.setCurrentProfileSection({
    ...profileStore.currentProfileSection!,
    form: markRaw(
      defineAsyncComponent(
        () => import(`../../components/Forms/${form}/${form}.vue`)
      )
    ),
  })
}

function closeForm() {
  return displayConfirmationModalIfTouched()
}

const updateProfileSectionSharedState = inject(
  updateProfileSectionSharedStateInjectionKey
)

function findFormThatIsOpening(formTitle: string) {
  return UPDATE_PROFILE_SECTIONS.find(
    (section) => t(section.title) === formTitle
  )
}

function isCurrentSectionTouched() {
  return updateProfileSectionSharedState!.items[
    profileStore.currentProfileSectionTitle
  ]?.touched
}

function displayConfirmationModalIfTouched() {
  if (!isCurrentSectionTouched()) {
    profileStore.setCurrentProfileSection(null)

    return true
  }

  setTimeout(() => {
    profileStore.setIsConfirmModalOpen(true)
  }, 100)

  return false
}

const form = computed(() => profileStore.currentProfileSection?.form)

// PROFILE SECTIONS
const profileSections = UPDATE_PROFILE_SECTIONS.map((profile) => ({
  title: t(profile.title),
  form: profile.form,
}))

// BACK
function returnPreviousRoute() {
  router.go(-1)
}
</script>
