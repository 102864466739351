<template>
  <footer class="border-t border-line bg-gray-100 py-12 print:hidden">
    <JContainer>
      <div class="flex items-center justify-between">
        <RouterLink
          :to="{ name: 'Search' }"
          class="flex flex-col items-center gap-x-8 no-underline"
        >
          <div class="flex lg:flex-1">
            <JLogo class="w-[190px]" src="/logo/budgetFly.svg" />
          </div>
        </RouterLink>

        <div
          class="flex items-center [&>*:first-child]:ps-0 [&>*:last-child]:last:pe-0 [&>*:not(:first-child)]:border-l [&>*:not(:first-child)]:border-line [&>*]:px-10 [&>a]:font-regular [&>a]:text-dark [&>a]:no-underline [&>button]:text-dark"
        >
          <h6 class="text-gray-500">
            {{ $t('copyRight', { brand: 'BudgetFly' }) }}
          </h6>

          <RouterLink to="/terms-and-conditions">
            {{ $t('termsAndConditions') }}
          </RouterLink>

          <RouterLink to="/privacy-policy">
            {{ $t('privacyPolicy') }}
          </RouterLink>

          <RouterLink to="/about-us">
            {{ $t('aboutUs') }}
          </RouterLink>

          <button @click="openHelpModal">
            {{ $t('helpAndSupport') }}
          </button>

          <RouterLink to="/about-us" class="text-dark no-underline">
            {{ $t('aboutUs') }}
          </RouterLink>
        </div>
      </div>
    </JContainer>
  </footer>
</template>

<script lang="ts" setup>
import { JContainer, JLogo } from '@journey/ui-kit'
import useHelpStore from '@/modules/Help/store'

// RECENT ACTIVITY
const helpStore = useHelpStore()

function openHelpModal() {
  helpStore.toggleHelpModal(true)
}
</script>
