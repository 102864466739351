<template>
  <JSelect
    v-model="internalModelValue"
    :error="error"
    :required="required"
    :options="options"
    :loading="isLoading"
    optionValue="type"
    optionKey="id"
    optionClass="flex items-center"
  >
    <template #option="{ option }">
      <template v-if="typeof option === 'object'">
        <div class="flex flex-col gap-y-4">
          <div class="mb-4 font-medium">
            {{ option.type }}
          </div>

          <div class="text-metadata-limited text-gray-500">
            {{ option.description }}
          </div>
        </div>
      </template>
    </template>
  </JSelect>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useVModel } from '@vueuse/core'
import { JSelect } from '@journey/ui-kit'
import type { Emits, Props } from './ContactSupportFormTypesSelectInput.types'
import { useContactSupportTypesQuery } from '@/modules/Support/requests/queries'
import type { ContactSupportType } from '@/modules/Support/types/model'

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

// V-MODEL
const internalModelValue = useVModel(props, 'modelValue', emit)

// DATA
const { data, isLoading } = useContactSupportTypesQuery()

const options = computed(
  () =>
    data.value?.data.map(({ id, type, description }: ContactSupportType) => ({
      id,
      type,
      description,
    })) || []
)
</script>
