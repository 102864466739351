import supportEn from './en/en.json'
import supportAr from './ar/ar.json'
import supportEs from './es/es.json'
import supportDe from './de/de.json'
import supportIt from './it/it.json'
import supportFr from './fr/fr.json'
import supportPt from './pt/pt.json'
import supportEl from './el/el.json'
import supportBn from './bn/bn.json'

const SupportMessages = {
  en: { support: { ...supportEn } },
  ar: { support: { ...supportAr } },
  es: { support: { ...supportEs } },
  de: { support: { ...supportDe } },
  it: { support: { ...supportIt } },
  fr: { support: { ...supportFr } },
  pt: { support: { ...supportPt } },
  el: { support: { ...supportEl } },
  bn: { support: { ...supportBn } },
}

export default SupportMessages
