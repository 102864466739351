import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { type ItinerarySummary, SearchTravelModes } from '../types'
import type { ItineraryList } from '../types/model/itinerary'
import useSearchPanelStore from './searchPanel'
import type { HotelInfoToReserve } from '@/modules/Search/types'
import type { Car, Flight } from '@/modules/Search/types/model/search'
import type { CarInfo } from '@/modules/Search/types/model/carInfo'

type Item = Car | Flight | HotelInfoToReserve

const useItinerarySummary = defineStore(
  'itinerarySummary',
  () => {
    const searchPanelStore = useSearchPanelStore()

    const isOpen = ref<ItinerarySummary['isOpen']>(false)
    const items = ref<ItinerarySummary['items']>([])
    const itineraries = ref<ItineraryList>()
    // SERVER DATA LOADING
    const itinerariesIsLoading = ref(false)
    const id = ref<string | null>(null)
    // FIXME: THIS WILL BE REMOVED IN FUTURE
    const isLoading = ref(false)

    const itineraryHasError = ref(false)
    function setItineraryHasError(value: boolean) {
      itineraryHasError.value = value
    }

    const getItemById = (id: string) =>
      items.value.find(({ uniqueId }) => uniqueId === id)
    const isItemFlight = (item: Item): item is Flight => 'slices' in item
    const isItemCar = (item: Item): item is Car => 'carType' in item
    const isItemHotel = (item: Item): item is HotelInfoToReserve =>
      'rooms' in item

    const getCurrency = computed(() => {
      return (
        itineraries.value?.flight?.[0]?.convertedCurrency ||
        itineraries.value?.car?.[0]?.convertedCurrency ||
        itineraries.value?.hotel?.[0]?.convertedCurrency
      )
    })
    const getPrice = computed(() => {
      if (!itineraries.value) {
        return 0
      }

      const prices = Object.values(itineraries.value)
        .flat()
        .map((item) => {
          if (isItemHotel(item)) {
            return (
              item.rooms.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  (currentValue.price || 0) * (currentValue?.quantity || 0),
                0
                // FIXME: TAX SHOULD BE REMOVED
              ) + 0
            )
          }

          return 'totalAmount' in item ? item.totalAmount : item.price
        })

      return prices.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      )
    })

    const getItemBySearchOption = (
      searchOption: number,
      itemType: SearchTravelModes
    ): Item | undefined => {
      const isItemType = {
        [SearchTravelModes.Flight]: isItemFlight,
        [SearchTravelModes.Car]: isItemCar,
        [SearchTravelModes.Hotel]: isItemHotel,
      }[itemType]

      return items.value.find(
        (item) => item.searchOption === searchOption && isItemType(item)
      )
    }
    const isFightExistsInCity = () => {
      const flightIndex = items.value.findIndex((i) => isItemFlight(i))
      return flightIndex !== -1
    }
    const isCarExistsInCity = (item: Car | CarInfo) => {
      const carIndex = items.value.findIndex((i) =>
        isItemCar(i) ? i.iataCode === item.iataCode : false
      )
      return carIndex !== -1
    }
    const isHotelExistsInCity = (item: HotelInfoToReserve) => {
      const hotelIndex = items.value.findIndex((i) =>
        isItemHotel(i)
          ? i.destination.countryCode === item.destination.countryCode
          : false
      )
      return hotelIndex !== -1
    }

    function toggle(value?: boolean) {
      if (typeof value === 'undefined') {
        isOpen.value = !isOpen.value

        return
      }

      isOpen.value = value
    }
    function select(item: Item) {
      if (isItemCar(item)) {
        if (searchPanelStore.isMultiDestination) {
          const carLocationFoundExist = items.value.find((i) =>
            isItemCar(i) ? i.iataCode === item.iataCode : false
          )

          if (carLocationFoundExist) {
            const carIndex = items.value.findIndex(
              (i) => i.uniqueId === carLocationFoundExist.uniqueId
            )
            items.value[carIndex] = item
          } else {
            items.value.push(item)
          }
        } else {
          const carIndex = items.value.findIndex((i) => isItemCar(i))

          if (carIndex === -1) {
            items.value.push(item)
          } else {
            items.value[carIndex] = item
          }
        }
      } else if (isItemFlight(item)) {
        const flightIndex = items.value.findIndex((i) => isItemFlight(i))
        if (flightIndex === -1) {
          items.value.push(item)
        } else {
          items.value[flightIndex] = item
        }
      } else if (isItemHotel(item)) {
        if (searchPanelStore.isMultiDestination) {
          const hotelLocationFoundExist = items.value.find((i) =>
            isItemHotel(i)
              ? i.destination.countryCode === item.destination.countryCode
              : false
          )

          if (hotelLocationFoundExist) {
            const hotelIndex = items.value.findIndex(
              (i) => i.uniqueId === hotelLocationFoundExist.uniqueId
            )
            items.value[hotelIndex] = item
          } else {
            items.value.push(item)
          }
        } else {
          const hotelIndex = items.value.findIndex((i) => isItemHotel(i))
          if (hotelIndex === -1) {
            items.value.push(item)
          } else {
            items.value[hotelIndex] = item
          }
        }
      }
    }
    function remove(id: Item['uniqueId']) {
      items.value = items.value.filter(({ uniqueId }) => id !== uniqueId)

      Object.entries(itineraries.value!).forEach(([key, value]) => {
        itineraries.value![key as 'hotel' | 'flight' | 'car'] = value.filter(
          ({ uniqueId }: { uniqueId: string }) => id !== uniqueId
        )
      })
    }

    const sortedItineraries = computed(() => {
      const { flight = [], hotel = [], car = [] } = itineraries.value ?? {}

      return {
        flight,
        hotel,
        car,
      }
    })

    const itineraryLength = computed(() => {
      const { flight = [], car = [], hotel = [] } = itineraries.value ?? {}
      return flight.length + car.length + hotel.length
    })

    function $reset() {
      isOpen.value = false
      items.value = []
      itineraries.value = undefined
      id.value = null
    }

    const itemsSearchOptions = computed(() => {
      const foundFlights = items.value
        .filter(isItemFlight)
        .map((f) => f.searchOption)
      const flights = foundFlights.length
        ? foundFlights
        : itineraries.value?.flight?.map((f) => f.searchOption) || []

      const foundCars = items.value.filter(isItemCar).map((c) => c.searchOption)
      const cars = foundCars.length
        ? foundCars
        : itineraries.value?.car?.map((c) => c.searchOption) || []

      const foundHotels = items.value
        .filter(isItemHotel)
        .map((h) =>
          h.roomRates.map((r) => ({
            searchOption: h.searchOption,
            roomCode: r.roomRate.roomCode,
            quantity: r.quantity,
          }))
        )
        .flat()
      const hotels = foundHotels.length
        ? foundHotels
        : itineraries.value?.hotel
            ?.map((h) =>
              h.rooms.map((r) => ({
                searchOption: h.searchOption,
                roomCode: r.roomCode,
                quantity: r.quantity!,
              }))
            )
            .flat() || []

      return {
        flights,
        cars,
        hotels,
      }
    })

    function setItineraries({ car, flight, hotel }: ItineraryList) {
      itineraries.value = {
        car,
        flight,
        hotel,
      }
    }

    return {
      id,
      isOpen,
      items,
      sortedItineraries,
      getCurrency,
      getPrice,
      getItemById,
      getItemBySearchOption,
      isItemFlight,
      isItemCar,
      isItemHotel,
      toggle,
      select,
      remove,
      $reset,
      itemsSearchOptions,
      isLoading,
      setItineraries,
      itineraries,
      itinerariesIsLoading,
      isFightExistsInCity,
      isCarExistsInCity,
      isHotelExistsInCity,
      itineraryLength,
      itineraryHasError,
      setItineraryHasError,
    }
  },
  {
    persist: {
      storage: window.localStorage,
      paths: ['id'],
    },
  }
)

export default useItinerarySummary
