<template>
  <div>
    <div>
      <slot />
    </div>

    <JConfirmation
      v-if="loyaltyStore.isConfirmModalOpen"
      v-model="loyaltyStore.isConfirmModalOpen"
      showCloseButton
      :title="
        $t('loyalty.wouldYouLikeToSaveYourChangesToForm', {
          form:
            $t(loyaltyStore.previousLoyaltySectionTitle) ||
            $t(loyaltyStore.currentLoyaltySectionTitle),
        })
      "
      :confirmText="$t('yes')"
      :cancelText="$t('no')"
      @confirm="confirm"
      @cancel="cancel"
      @close="close"
    />
  </div>
</template>

<script setup lang="ts">
import { provide, ref } from 'vue'
import { JConfirmation } from '@journey/ui-kit'
import type { LoyaltySectionSharedStateProvider } from '@/modules/Loyalty/types'
import useLoyaltyStore from '@/modules/Loyalty/store'
import { useDevicePlatform } from '@/modules/Core/composables'
import {
  ABOUT_LOYALTY_ACCOUNT_PROGRAM_FORM_INJECTION_KEY,
  ACCOUNT_ACTIVITY_REPORT_FORM_INJECTION_KEY,
  LOYALTY_FORMS,
  MESSAGE_LOYALTY_ACCOUNT_TEAM_FORM_INJECTION_KEY,
  loyaltySectionSharedStateInjectionKey,
} from '@/modules/Loyalty/constants'

const loyaltyStore = useLoyaltyStore()

const { isMobile } = useDevicePlatform()

const updateLoyaltySectionSharedState = ref<LoyaltySectionSharedStateProvider>({
  [ACCOUNT_ACTIVITY_REPORT_FORM_INJECTION_KEY]: {
    touched: false,
    isLoading: false,
    submit: () => {},
  },
  [MESSAGE_LOYALTY_ACCOUNT_TEAM_FORM_INJECTION_KEY]: {
    touched: false,
    isLoading: false,
    submit: async () => {},
  },
  [ABOUT_LOYALTY_ACCOUNT_PROGRAM_FORM_INJECTION_KEY]: {
    touched: false,
    isLoading: false,
    submit: () => {},
  },
})

provide(loyaltySectionSharedStateInjectionKey, {
  items: updateLoyaltySectionSharedState.value,
  setTouched: (title: string, to: boolean) => {
    updateLoyaltySectionSharedState.value[title].touched = to
  },
})

function confirm() {
  updateLoyaltySectionSharedState.value[
    loyaltyStore.currentLoyaltySectionTitle
  ].submit(loyaltyStore.confirmationPayload)

  updateLoyaltySectionSharedState.value[
    loyaltyStore.currentLoyaltySectionTitle
  ].touched = false

  loyaltyStore.setConfirmationPayload(null)

  cancel()
}

function close() {
  loyaltyStore.setIsConfirmModalOpen(false)

  if (isMobile.value) {
    return
  }

  if (loyaltyStore.nextFormIndex === -1) {
    return
  }

  loyaltyStore.setPreviousLoyaltySection(null)
  loyaltyStore.setNextLoyaltyForm(-1)
}

function findAccordionThatIsOpening(idx: number) {
  return LOYALTY_FORMS.find((_section, index) => index === idx)
}

function cancel() {
  loyaltyStore.setPreviousLoyaltySection(null)
  loyaltyStore.setConfirmationPayload(null)

  if (loyaltyStore.currentLoyaltySectionTitle) {
    updateLoyaltySectionSharedState.value[
      loyaltyStore.currentLoyaltySectionTitle
    ].touched = false
  }

  if (loyaltyStore.previousLoyaltySectionTitle) {
    updateLoyaltySectionSharedState.value[
      loyaltyStore.previousLoyaltySectionTitle
    ].touched = false
  }

  if (isMobile.value) {
    loyaltyStore.setCurrentLoyaltySection(null)

    close()

    return
  }

  if (loyaltyStore.shouldCloseLoyaltyModal) {
    loyaltyStore.setIsLoyaltyModalOpen(false)
    loyaltyStore.setShouldCloseLoyaltyModal(false)
    loyaltyStore.setActiveFormIndex(-1)
    loyaltyStore.setCurrentLoyaltySection(null)
  } else {
    loyaltyStore.setActiveFormIndex(loyaltyStore.nextFormIndex)
    loyaltyStore.setCurrentLoyaltySection(
      findAccordionThatIsOpening(loyaltyStore.nextFormIndex)
    )
  }

  close()
}
</script>
