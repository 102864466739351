import type { UseQueryOptions } from '@tanstack/vue-query'
import { useQuery } from '@tanstack/vue-query'
import type { FetchCustomerLanguageResponse } from '@/modules/Core/types/api/response/customerLanguage'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const QUERY_KEY = 'customer-language'
const ENDPOINT = '/CustomerLanguage/all'

export function fetchCustomerLanguage() {
  return axiosInstance
    .get<FetchCustomerLanguageResponse>(ENDPOINT)
    .then((response) => response.data)
}

export function useCustomerLanguageQuery(
  options?: UseQueryOptions<Awaited<ReturnType<typeof fetchCustomerLanguage>>>
) {
  return useQuery({
    queryFn: fetchCustomerLanguage,
    queryKey: [QUERY_KEY],
    ...options,
  })
}
